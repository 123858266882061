/*
 * @Description:
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2022-06-09 10:37:24
 * @LastEditors: YGQ
 * @LastEditTime: 2023-11-17 18:36:46
 */
import Fingerprint2 from "fingerprintjs2";

/**
 * @description: 根据运行环境判断地址
 * @return {*}
 * @author: YGQ
 */
export function getEnvironment () {
  const domain = window.location.hostname;
  if (domain.includes("pms.geemro.com")) return "release"; // 判断生产环境
  if (domain.includes("trial.geemro.com")) return "trial"; // 判断体验环境
  if (domain.includes("dev.geemro.com")) return "develop"; // 判断开发环境
  if (domain.includes("localhost") || domain.includes("192.168")) return "local"; // 判断开发环境
  return "release";
}

/**
 * @description: 节流函数 将多次执行变成每隔一段时间执行。
 * @param {fn，interval} 
 * @return:{rule}
 */
export function throttle (fn, delay) {
  let open = true;
  return function (...args) {
    let that = this
    if (!open) {
      return false
    }
    // 工作时间，执行函数并且在间隔期内把状态位设为无效
    open = false
    setTimeout(() => {
      fn.apply(that, args)
      open = true;
    }, delay)
  }
}

// 获取浏览器指纹
export function getBrowserFingerprint () {
  return new Promise((resolve) => {
    Fingerprint2.get(function (components) {
      const values = components.map(function (component, index) {
        if (index === 0) {
          // 将微信浏览器中的UA中的网络类型（如wifi或4G）替换为空字符串，以避免切换网络导致指纹不一致
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      // 生成最终的指纹码 murmur
      const murmur = Fingerprint2.x64hash128(values.join(""), 31);
      resolve(murmur);
    });
  });
}