export default {
  mounted() {
    document.dispatchEvent(new Event("render-event"));
    this.getWxJsSdkSignature().then(res => {
      this.$wx.config({
        debug: false,
        appId: "wx51c283b35f02c5d7",
        timestamp: res.timestamp,
        nonceStr: res.noncestr,
        signature: res.signature,
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"]
      });
      this.$wx.ready(() => {
        this.wxAppMessageShare();
        this.wxTimelineShare();
      });
    });
    console.log('%c职位开放中，欢迎投递(邮箱)：hr@geemro.com', 'color: #03B8B2; font-weight: bold;');
  },
  methods: {
    async getWxJsSdkSignature() {
      const [res, err] = await this.$post("getWxJsSdkSignature", {
        url: location.href.split("#")[0]
      });
      if (err) return this.$msg(err);
      return res;
    },
    // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
    wxAppMessageShare() {
      this.$wx.updateAppMessageShareData({
        title: "更多酒店订单就在杰姆龙",
        // 分享标题
        desc: "为酒店和民宿搭建直销订房平台，提供渠道流量互通服务",
        // 分享描述
        link: "https://www.geemro.com",
        // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://dev.geemro.com/geemro.jpg",
        // 分享图标
        success: function () {
          // 设置成功
        }
      });
    },
    // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
    wxTimelineShare() {
      this.$wx.updateTimelineShareData({
        title: "更多酒店订单就在杰姆龙",
        // 分享标题
        link: "https://www.geemro.com",
        // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://dev.geemro.com/geemro.jpg",
        // 分享图标
        success: function () {
          // 设置成功
        }
      });
    }
  }
};