import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-322cf047"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "fixed-top header",
  id: "header",
  ref: "header"
};
const _hoisted_2 = {
  class: "flex-between"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("header", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "logo",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.goHome && $options.goHome(...args))
  }), _createElementVNode("button", {
    class: "primary-button-plain",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.goRegister && $options.goRegister(...args))
  }, "免费使用")])], 512);
}