/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-08-28 15:18:59
 * @LastEditors: YGQ
 * @LastEditTime: 2023-11-21 18:26:45
 */
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { getBrowserFingerprint } from '@/utils';

// 根据ASCII码进行排序
function sortQueryParams (params) {
  const sortedParams = {};
  const keys = Object.keys(params).sort();

  for (const key of keys) {
    if (key !== 'sign' && !(params[key] instanceof Uint8Array)) {
      sortedParams[key] = params[key];
    }
  }

  return sortedParams;
}

// 将排序好的参数名和参数值拼接，得到 query 串
function buildQueryString (sortedParams) {
  const queryString = Object.entries(sortedParams)
    .map(([key, value]) => `${key}${value}`)
    .join('');
  return queryString;
}

// 对接口中的 body 请求参数去掉空格、换行符，进行 1 次转义，得到 body 串
function processRequestBody (bodyParams) {
  const processedBody = JSON.stringify(bodyParams)
    .replace(/\s/g, '') // 去除空格
    .replace(/\n/g, '') // 去除换行符
    .replace(/\\/g, '\\\\') // 转义反斜杠
    .replace(/\//g, '\\/') // 转义斜杠
  return processedBody;
}


// query串 + body串，得到拼接字符串 string
function concatenateStrings (queryString, bodyString) {
  const concatenatedString = `${queryString}${bodyString}`;
  return concatenatedString;
}

// {a:'a', b:'b'} => a=a&b=b
function objectToQueryString (obj) {
  const keyValuePairs = [];
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(obj[key]);
      keyValuePairs.push(`${encodedKey}=${encodedValue}`);
    }
  }
  return keyValuePairs.join('&');
}

// 生成加密的sign
function encryption (bodyParams, queryParams) {
  // 将 query 参数根据 ASCII 码进行排序
  const sortedParams = sortQueryParams(queryParams);
  // 将排序好的 query 参数名和参数值拼接，得到 query 串
  const queryString = buildQueryString(sortedParams);
  // 对接口中的 body 请求参数去掉空格、换行符，进行 1 次转义，得到 body 串
  const bodyString = processRequestBody(bodyParams);
  // 按 query串 + body串 的顺序拼接，得到拼接字符串 string
  const signString = concatenateStrings(queryString, bodyString);
  // 进行md5加密
  const sign = CryptoJS.MD5(signString).toString()
  // 得到的十六进制字符串大写
  return sign.toUpperCase()
}

// 在请求的query中注入验证参数
export async function paramsInject (bodyParams, isToString = true) {
  const body = JSON.parse(JSON.stringify(bodyParams))
  const query = {
    request_id: uuidv4(),
    timestamp: new Date().getTime(),
  }
  query.fid = await getBrowserFingerprint()// 生成浏览器指纹
  query.sign = encryption(body, query) //生成签名
  if (isToString) return objectToQueryString(query)
  return { ...query, ...bodyParams }
}