/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-11-15 16:07:11
 * @LastEditors: YGQ
 * @LastEditTime: 2023-11-15 16:07:28
 */
export const URL = {
  release: "https://pms.geemro.com/geemro-web-php/public/index.php/", // 发布版接口地址
  trial: "https://trial.geemro.com/geemro-web-php/public/index.php/", // oneid 开发环境接口地址
  develop: "https://dev.geemro.com/geemro-web-php/public/index.php/", // 开发版接口地址
  local: "/api-proxy/", // 本地版接口地址
  mock: "http://192.168.2.99:4523/mock/997517/", // 本地 MOCK 接口地址
};