import "core-js/modules/es.array.push.js";
import HomeHero from "./components/HomeHero";
import HomeMain from "./components/HomeMain";
import HomeFooter from "./components/HomeFooter";
import { BasseHeader, BaseFixedDialog } from "@/components";
export default {
  name: "HomeView",
  components: {
    BasseHeader,
    HomeHero,
    HomeMain,
    HomeFooter,
    BaseFixedDialog
  },
  metaInfo() {
    return {
      title: "杰姆龙-免费酒店民宿公寓PMS和数智营销工具",
      meta: [{
        charset: "utf-8"
      }, {
        name: "keywords",
        content: "杰姆龙,杰姆龙酒店,杰姆龙预订"
      }, {
        name: "description",
        content: "为酒店和民宿搭建直销订房平台，提供渠道流量互通服务"
      }],
      link: [{
        rel: "杰姆龙",
        href: "https://www.geemro.com/#/"
      }]
    };
  },
  data() {
    return {
      headerRef: null,
      homePageOffset: 0,
      swiperOption: {},
      lastScrollDistance: null,
      isScrollingToMain: false // 添加标志变量
    };
  },

  mounted() {
    // 获取页面元素的偏移量
    this.initElInfo();
    // 监听页面滚动
    window.addEventListener("scroll", this.handleScroll);
  },
  onBeforeUnmount() {
    // 页面卸载时移除监听
    window.removeEventListener("scroll", this.handleScroll);
    this.isScrollingToMain = false;
  },
  methods: {
    goRegister() {
      this.$router.push("/register");
    },
    initElInfo() {
      window.scrollTo(0, 0);
      // 获取顶部header元素的的ref
      this.headerRef = this.$refs.header.$refs.header;
      // 获取首屏展示的高度
      const headerHeight = this.headerRef.offsetHeight;
      // 首屏展示元素距离顶部的距离 = hero的高度 - 固定的header高度
      this.heroHeight = this.$refs.hero.$el.offsetHeight - headerHeight;
      // 页面主体内容距离顶部的距离 = main距离顶部的距离 - 固定的header高度
      this.homePageOffset = this.$refs.main.$el.offsetTop - headerHeight;
      this.isScrollingToMain = false;
    },
    handleScroll() {
      const scrollY = window.scrollY;
      // 检查滚动方向
      const direction = scrollY > this.lastScrollDistance ? "down" : "up";
      this.lastScrollDistance = scrollY;
      // 向下滚动并且  100 <= 滚动距离 <= 首屏展示元素距离顶部的距离
      const isScrollTo = direction === "down" && scrollY >= 100 && scrollY < this.heroHeight;
      // 判断是否处于滚动中
      if (!this.isScrollingToMain && isScrollTo) {
        this.isScrollingToMain = true; // 设置标志变量为 true
        this.$scrollTo("#main", 1000, {
          offset: -55,
          easing: "ease-in"
        });
      }
    }
  }
};