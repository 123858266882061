import { BaseDialog } from "@/components";
export default {
  components: {
    BaseDialog
  },
  props: {
    isShowCallButton: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: "31.3125rem"
    }
  },
  data() {
    return {
      record: {},
      title: ""
    };
  },
  methods: {
    show(title) {
      this.title = title;
      this.$refs.baseDialog.show();
    },
    close() {
      this.$refs.baseDialog.close();
    },
    makePhoneCall(phoneNumber) {
      const telUrl = "tel:" + phoneNumber;
      window.location.href = telUrl;
    }
  }
};