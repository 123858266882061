/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-11-14 11:13:09
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-18 14:03:48
 */
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      metaInfo: {
        description: "杰姆龙为酒店和民宿搭建直销订房平台，提供渠道流量互通服务,免费注册使用杰姆龙系统",
        keywords: "杰姆龙,杰姆龙酒店,杰姆龙预订,杰姆龙系统,杰姆龙PMS",
      }
    },
    component: HomeView
  },
  {
    path: '/register',
    name: 'register',
    metaInfo: {
      description: "免费注册使用杰姆龙系统",
      keywords: "杰姆龙系统,杰姆龙注册,智能酒店,酒店系统,PMS",
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/register')
  },
  {
    path: '/about',
    name: 'about',
    metaInfo: {
      description: "关于杰姆龙",
      keywords: "杰姆龙介绍,中小集团,加入杰姆龙,",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/about')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
