/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-11-14 11:13:09
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-15 18:51:21
 */
import { createApp, } from 'vue'
import App from './App.vue'
import router from './router'
import "./styles/index.scss"; // 全局样式库
import request from "./api/request.js"; // 封装的 http 请求方法
import vantApi from "./api/vant.js"; // 封装的 http 请求方法
import clipboard from "clipboard";
import wx from 'weixin-js-sdk';
import aegisWebSdk from "./aegisWebSdk.JS"; // 腾讯云前端监控
import { createMetaManager } from "vue-meta";

/* 引入aos动画库相关文件 */
import AOS from 'aos'
import 'aos/dist/aos.css'

// 引入本次svgicon
import "@/svgIcon/index"
import svgIcon from "@/svgIcon/index.vue";

import 'vant/es/toast/style';

import VueScrollTo from 'vue-scrollto'

/* aos动画初始化 */
AOS.init({
  duration: 1000,
  easing: "ease-in-out",
  initClassName: 'aos-init', // 默认初始化类
  animatedClassName: 'aos-animate', // 默认动画类
  once: true,// 动画只执行一次
  mirror: false
});

const metaManager = createMetaManager(false, {
  meta: { tag: 'meta', nameless: true },
});

const app = createApp(App)

app.config.globalProperties.$clipboard = clipboard // 全局挂载事件总线
app.config.globalProperties.$wx = wx // 全局挂载事件总线

app.use(router)
app.use(request)
app.use(vantApi)
app.use(VueScrollTo)
app.use(aegisWebSdk)
app.use(metaManager)

app.component('svg-icon', svgIcon)
app.mount('#app')
