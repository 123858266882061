import "core-js/modules/es.array.push.js";
export default {
  mounted() {
    this.headerRef = this.$refs.header;
    // 监听页面滚动
    window.addEventListener("scroll", this.handleScroll);
  },
  onBeforeUnmount() {
    // 页面卸载时移除监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    goRegister() {
      this.$router.push("/register");
    },
    goHome() {
      this.$router.push("/");
    },
    handleScroll() {
      const scrollY = window.scrollY;
      // 动态给顶部header添加阴影
      if (scrollY > 100) {
        this.headerRef.classList.add("header-shadow");
      } else {
        this.headerRef.classList.remove("header-shadow");
      }
    }
  }
};