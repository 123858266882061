/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-03-30 16:51:42
 * @LastEditors: YGQ
 * @LastEditTime: 2023-12-14 09:24:12
 */
import Aegis from 'aegis-web-sdk';
import { getEnvironment } from '@/utils';
const version = require('../package.json').version

const environment = {
  develop: 'development',
  trial: 'pre',
  local: 'local',
}

const ENV = getEnvironment().includes('release') ? 'production' : environment[getEnvironment()] || 'others'

const aegis = new Aegis({
  version,
  id: '9GD7xcLXo1ZEz152Lz', // 上报 id
  env: ENV,// 区分环境
  spa: true, // spa 应用页面跳转的时候开启 pv 计算
  hostUrl: 'https://rumt-zh.com',
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  api: {
    apiDetail: true, // 上报接口请求参数和返回值
    reportRequest: true, // 全量接口上报
  },
});

const install = (app) => {
  app.config.globalProperties.$aegis = aegis;
};

export default {
  install,
};
