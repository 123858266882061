import "core-js/modules/es.array.push.js";
import { BaseDialogWx } from "@/components";
export default {
  name: "HomeFooter",
  components: {
    BaseDialogWx
  },
  methods: {
    // 跳转到关于我们页面
    goAbout() {
      this.$router.push("/about");
    },
    // 外部链接跳转
    to(url) {
      // if (isElectron()) return false // 桌面应用环境不跳转
      if (url == "miit") {
        window.open("https://beian.miit.gov.cn"); // 工信部备案
      } else if (url == "beian") {
        window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202002386"); // 公安备案
      }
    }
  }
};