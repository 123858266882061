export default {
  props: {
    styleObj: {
      type: Object,
      default: () => ({
        width: "300px",
        height: "300px"
      })
    }
  },
  data() {
    return {
      visible: false,
      msg: "",
      btnTxt: ""
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    }
  }
};