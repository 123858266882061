import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-385d0ad0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "modal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "modal-mask",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.close && $options.close(...args))
  }), _createElementVNode("div", {
    class: "modal-content",
    style: _normalizeStyle($props.styleObj)
  }, [_renderSlot(_ctx.$slots, "content", {}, undefined, true)], 4)], 512)), [[_vShow, $data.visible]]);
}