import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-501fab58"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home base-bg",
  ref: "home"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BasseHeader = _resolveComponent("BasseHeader");
  const _component_HomeHero = _resolveComponent("HomeHero");
  const _component_HomeMain = _resolveComponent("HomeMain");
  const _component_HomeFooter = _resolveComponent("HomeFooter");
  const _component_BaseFixedDialog = _resolveComponent("BaseFixedDialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_BasseHeader, {
    id: "header",
    ref: "header"
  }, null, 512), _createElementVNode("div", _hoisted_1, [_createVNode(_component_HomeHero, {
    ref: "hero"
  }, null, 512), _createVNode(_component_HomeMain, {
    ref: "main"
  }, null, 512)], 512), _createVNode(_component_HomeFooter), _createVNode(_component_BaseFixedDialog)], 64);
}