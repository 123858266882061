/*
 * @Description: 
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2023-05-10 15:23:04
 * @LastEditors: YGQ
 * @LastEditTime: 2023-11-15 17:16:35
 */
import { showToast } from 'vant';

// options 表示 为可选配置项
async function msg (message) {
  showToast(message)
}

const install = (app,) => {
  app.config.globalProperties.$msg = msg
};

export default {
  install,
};