/*
 * @Description:
 * @Version: 2.0
 * @Autor: YGQ
 * @Date: 2022-07-09 19:57:22
 * @LastEditors: YGQ
 * @LastEditTime: 2023-11-21 18:03:39
 */
import axios from "axios";
import { getEnvironment } from "@/utils";
import { URL } from "@/const/";
import { showLoadingToast, closeToast, showToast } from 'vant';
import { paramsInject } from '@/utils/encryption'
axios.defaults.withCredentials = true;

const toastOptions = { message: '加载中...', forbidClick: true, duration: 0 }

const ENV = getEnvironment();
const BASE_URL = URL[ENV];
const MOCK_URL = URL.mock;

/**
 * get 请求
 * @param {String} api 请求的api接口
 * @param {Object} [params] 请求参数[可选]
 * @return {Function} 请求成功后继续处理数据
 */
async function get (api, params = {}) {
  params = requestFit(params); // 请求前的数据适配
  let url = !params.mock ? BASE_URL + api : MOCK_URL + api;
  showLoadingToast(toastOptions)
  try {
    const response = await axios.get(url, { params });
    return onSuccess(api, params, response, this); // 请求成功回调
  } catch (error) {
    closeToast()
    showToast("请求超时，请稍后刷新重试");
  }
}

/**
 * post 请求
 * @param {String} api 请求的api接口
 * @param {Object} params 请求参数
 * @return {Function} 请求成功后继续处理数据
 */
async function post (api, params = {}) {
  params = requestFit(params); // 请求前的数据适配
  let url = !params.mock ? BASE_URL + api : MOCK_URL + api;
  const inject = await paramsInject(params)
  url = url + `?${inject}`
  showLoadingToast(toastOptions)
  try {
    const response = await axios.post(url, params);
    return onSuccess(api, params, response); // 请求成功回调
  } catch (error) {
    closeToast()
    showToast("请求超时，请稍后刷新重试");
  }
}

/**
 * 请求成功回调
 * @param {String} api 请求的api接口
 * @param {Object} params 请求参数
 * @param {Promise} response 请求返回
 * @return {Array} 返回[res, err]其中报错时才有err参数
 */
function onSuccess (api, params, response) {
  const data = responseFit(response?.data); // 对返回数据适配
  if (process.env.NODE_ENV === "development") {
    console.group(api, params);
    if (params.mock) console.warn("返回 MOCK 数据");
    console.log(data); // 开发环境下控制台打印返回
    console.groupEnd();
  }
  closeToast()
  if (data && data.ok) {
    return [data.data, null]; // 正常时将提取出里层data返回
  } else if (data && !data.ok) {
    return [null, data.msg || "", data.data || []]; // 异常时返回信息
  }
}

/**
 * 请求发送前的数据适配
 * @param {Object} data 请求参数
 * @return {Object} 经处理的data
 */
function requestFit (data) {
  return camel2snake(data); // 驼峰转下划线
}

/**
 * 数据返回后的数据适配
 * @param {Object} data 请求参数
 * @return {Object} 经处理的data
 */
function responseFit (data) {
  return snake2camel(data); // 下划线转驼峰
}

// 将 camelCase 转为 snake_case
function camel2snake (data) {
  if (typeof data !== "object" || !data) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((item) => camel2snake(item));
  }
  const newData = {};
  for (const key in data) {
    const newKey = key.replace(/([A-Z])/g, (p, m) => `_${m.toLowerCase()}`);
    newData[newKey] = camel2snake(data[key]);
    if (
      data[key] &&
      Number.isFinite(data[key]) &&
      parseInt(data[key], 10) != data[key]
    ) {
      newData[newKey] = Number(data[key].toFixed(2));
    }
  }
  return newData;
}

// 将 snake_case 转为 camelCase
function snake2camel (data) {
  if (typeof data !== "object" || !data) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((item) => snake2camel(item));
  }
  const newData = {};
  for (const key in data) {
    const newKey = key.replace(/_([a-z])/g, (p, m) => m.toUpperCase());
    newData[newKey] = snake2camel(data[key]);
  }
  return newData;
}

/**
 * 将请求方法注入全局
 * 需在 main.js 里面 use 本方法
 */
const install = (app) => {
  app.config.globalProperties.$host = BASE_URL;
  app.config.globalProperties.$post = post;
  app.config.globalProperties.$get = get;
};

export default {
  install,
};
